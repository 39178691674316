import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["collapsible"]
  }

  collapseAll(event) {
    this.collapse(event, true)
  }

  collapseOne(event) {
    if (event.target.classList.contains("collapsible")) {
      this.collapse(event, false)
    }
    event.stopPropagation()
  }

  collapse(event, collapseAll) {
    const elem = event.currentTarget
    const collapsed = !elem.classList.contains("collapsed")
    if (collapseAll) {
      this.collapsibleTargets.forEach(parent => this.setCollapsed(parent, collapsed))
    }
    this.setCollapsed(elem, collapsed)
  }

  setCollapsed(elem, collapsed) {
    elem.classList.toggle("collapsed", collapsed)
  }
}
