import {Controller} from "@hotwired/stimulus"
import {useResize} from "stimulus-use"

export default class extends Controller {
  static values = { varName: { type: String }, propName: { type: String } }

  connect() {
    useResize(this)
  }

  resize() {
    const value = `${this.element[this.propNameValue]}px`
    document.documentElement.style.setProperty(this.varNameValue, value)
  }
}
