import { Controller } from '@hotwired/stimulus'
import {useThrottle} from 'stimulus-use'

export default class extends Controller {
  static throttles = ["calculateActiveIndex"]
  static values = {
    mask: { type: Boolean, default: true },
    scrollBehavior: { type: String, default: "smooth" },
    verticalDirection: { type: Boolean, default: false },
  }
  static targets = ["container", "prev", "next", "item", "dot"]

  connect() {
    useThrottle(this, { wait: 150 })
    this.index = 0
    this._updateClasses()
  }

  scrollToIndexByParam({ params: { index }}) {
    if (index !== this.index) {
      this.index = index
      this._scroll()
      this._updateAndDispatch()
    }
  }

  scrollToIndex({ detail: { index }}) {
    if (index !== this.index && index >= 0 && index < this.itemTargets.length) {
      this.index = index
      this._scroll()
      this._updateAndDispatch()
    }
  }

  scrollToPrevious() {
    this.scrollToIndex({ detail: { index: this.index - 1 } })
  }

  scrollToNext() {
    this.scrollToIndex({ detail: { index: this.index + 1 } })
  }

  scrollToRandom() {
    this.index = Math.floor(Math.random() * this.itemTargets.length)
    this._scroll()
    this._updateAndDispatch()
  }

  calculateActiveIndex() {
    if (this.ignoreScrollEvents) {
      return
    }
    let scrollPosition = this._scrollPosition()
    const maxScroll = this._maxScroll()
    const index = scrollPosition > maxScroll - 50
        ? this.itemTargets.length - 1
        : Math.round(scrollPosition / this._itemSize())
    if (index !== this.index) {
      this.index = index
      this._updateAndDispatch()
    }
  }

  _scroll() {
    const scrollConfig = {
      [this._scrollToKey()]: this.index * this._itemSize(),
      behavior: this.scrollBehaviorValue
    }
    this.ignoreScrollEvents = true
    this.containerTarget.scrollTo(scrollConfig)
    setTimeout(() => this.ignoreScrollEvents = false, 500)
  }

  _updateAndDispatch() {
    this._updateClasses()
    this.dispatch("indexChanged", { detail: { index: this.index } })
  }

  _updateClasses() {
    this.itemTargets.forEach((t, i) => t.classList.toggle("active", i === this.index))
    const prev = this.index > 0
    const next = this.index < this.itemTargets.length - 1
    if (this.hasPrevTarget) {
      this.prevTarget.classList.toggle("hidden", !prev)
    }
    if (this.hasNextTarget) {
      this.nextTarget.classList.toggle("hidden", !next)
    }
    if (this.hasDotTarget) {
      this.dotTargets.forEach((dot, index) => dot.classList.toggle("active", index === this.index))
    }
    if (this.maskValue) {
      this.containerTarget.classList.toggle("mask-gradient-l", prev && !next)
      this.containerTarget.classList.toggle("mask-gradient-r", next && !prev)
      this.containerTarget.classList.toggle("mask-gradient-h", prev && next)
    }
  }

  _scrollPosition() {
    if (this.verticalDirectionValue) {
      return this.containerTarget.scrollTop
    } else {
      return this.containerTarget.scrollLeft
    }
  }

  _maxScroll() {
    if (this.verticalDirectionValue) {
      return this.containerTarget.scrollHeight - this.containerTarget.clientHeight
    } else {
      return this.containerTarget.scrollWidth - this.containerTarget.clientWidth
    }
  }

  _itemSize() {
    if (this.verticalDirectionValue) {
      return this.itemTarget.offsetHeight
    } else {
      return this.itemTarget.offsetWidth
    }
  }

  _scrollToKey() {
    return this.verticalDirectionValue ? "top" : "left";
  }
}
