import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["artwork", "quote", "navDot"]
  }

  connect() {
    this.index = 0
    this.autoplay = true
    this.updateThought()
  }

  setThought(event) {
    this.autoplay = false
    clearTimeout(this.timeout)
    this.index = event.params.index
    this.updateThought()
  }

  updateThought() {
    const setActive = (t, i) => t.classList.toggle("active", i === this.index)
    this.artworkTargets.forEach(setActive)
    this.quoteTargets.forEach(setActive)
    this.navDotTargets.forEach(setActive)

    if (this.autoplay) {
      this.timeout = setTimeout(() => {
        this.index = (this.index + 1) % this.quoteTargets.length
        this.updateThought()
      }, 10000)
    }
  }
}
