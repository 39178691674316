export function secondsToHm(str) {
  const sec_num = parseInt(str, 10);
  let hours = Math.floor(sec_num / 3600).toString().padStart(2, "0");
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60).toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function secondsToSeconds(str) {
  const sec_num = parseInt(str, 10);
  let hours = Math.floor(sec_num / 3600).toString().padStart(2, "0");
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60).toString().padStart(2, "0");
  let seconds = (sec_num - (hours * 3600) - (minutes * 60)).toString().padStart(2, "0");
  return `${seconds}`;
}

export function hmToSeconds(hms) {
  const [hours, minutes] = hms.split(':').map(Number);
  return hours * 3600 + minutes * 60;
}
