import {Controller} from "@hotwired/stimulus"
import Timer from "timer.js"
import {hmToSeconds, secondsToHm, secondsToSeconds} from "../lib/hms"

export default class extends Controller {
  static targets = [
    "hmInput",
    "secondsDisplay",
    "remainingPercentage",
    "start",
    "stop",
    "sessionTrackingForm",
    "submitButton"
  ];

  static values = { audioUrl: String };

  connect() {
    this.intervalBeforeSessionStart = 5;
    this.initialSessionLength = hmToSeconds(this.hmInputTarget.value);
    this.loadAudio();
    this.reset();
  }

  reset() {
    this.wakeLock?.release();
    const remainingSeconds = this.initialSessionLength + this.intervalBeforeSessionStart;
    this.hmInputTarget.value = secondsToHm(remainingSeconds);
    this.remainingPercentageTarget.style.strokeDasharray = `100, 100`;
    delete this.element.dataset['playing'];
    delete this.element.dataset['starting'];
    this.hmInputTarget.readOnly = false;
    this.timer?.stop();
    this.timer = undefined;
    this.sessionStartedAt = undefined;
    this.setRemainingSeconds(this.initialSessionLength, false);
  }

  setRemainingSeconds(seconds, playing = true) {
    this.hmInputTarget.value = secondsToHm(seconds);
    this.secondsDisplayTarget.innerText = secondsToSeconds(seconds);
    const playingOffset = playing ? -1 : 0;
    let percent = Math.min((seconds + playingOffset) / this.initialSessionLength * 100, 100);
    this.remainingPercentageTarget.style.strokeDasharray = `${percent}, 100`;
  }

  validateTime() {
    const duration = hmToSeconds(this.hmInputTarget.value);
    const min = hmToSeconds(this.hmInputTarget.min);
    const max = hmToSeconds(this.hmInputTarget.max);
    if (duration < min) {
      this.initialSessionLength = min;
      this.setRemainingSeconds(min, false);
    } else if (duration > max) {
      this.initialSessionLength = max;
      this.setRemainingSeconds(max, false);
    }
  }

  async start() {
    await this.requestWakeLock();
    this.playMutedAudio();
    this.initialSessionLength = hmToSeconds(this.hmInputTarget.value);
    this.validateTime();
    this.timer = new Timer({
      tick: 1,
      ontick: (millis) => this.setRemainingSeconds(Math.round(millis/1000)),
      onstart: this.onSessionStart.bind(this),
      onstop: this.onSessionStop.bind(this),
      onend: this.onSessionEnd.bind(this)
    });
    this.timer.start(this.initialSessionLength + this.intervalBeforeSessionStart);
    this.element.dataset['playing'] = '';
    this.hmInputTarget.readOnly = true;
  }

  onSessionStart() {
    this.sessionStartedAt = new Date();
    this.setRemainingSeconds(Math.round(this.timer.getDuration()/1000));
    this.element.dataset['starting'] = '';
    this.startSound = setTimeout(() => {
      this.playAlarm();
      delete this.element.dataset['starting'];
    }, this.intervalBeforeSessionStart * 1000);
  }

  onSessionStop() {
    clearTimeout(this.startSound);
    this.sendSessionData();
  }

  onSessionEnd() {
    this.playAlarm();
    this.sendSessionData();
    this.reset();
  }

  sendSessionData() {
    const form = this.sessionTrackingFormTarget;
    form._user_session_started.value = this.sessionStartedAt.toISOString();
    form._user_session_ended.value = new Date().toISOString();
    this.submitButtonTarget.click();
  }

  loadAudio() {
    return fetch(this.audioUrlValue)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
          return this.audioContext.decodeAudioData(arrayBuffer);
        })
        .then(decodedBuffer => {
          this.audioBuffer = decodedBuffer;
        })
        .catch(error => {
          console.error('Error loading audio:', error);
        });
  }

  playAlarm() {
    if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }
    const source = this.audioContext.createBufferSource();
    source.buffer = this.audioBuffer;
    source.connect(this.audioContext.destination);
    source.start();
  }

  playMutedAudio() {
    const source = this.audioContext.createBufferSource();
    source.buffer = this.audioBuffer;
    const gainNode = this.audioContext.createGain();
    gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
    source.connect(gainNode);
    gainNode.connect(this.audioContext.destination);
    source.start();
  }

  async requestWakeLock() {
    try {
      this.wakeLock = await navigator.wakeLock?.request('screen');
    } catch (err) {
      console.error(err);
    }
  }
}
